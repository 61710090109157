<div class="admin-container">
  <mat-toolbar color="primary">
    <button mat-icon-button (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="ml-3">Planitnerd Admin</h1>
  </mat-toolbar>
  <mat-sidenav-container [hasBackdrop]="false" class="sidenav-container">
    <mat-sidenav
      #drawer
      class="sidenav"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="!(isHandset$ | async)"
      [fixedInViewport]="false"
      [fixedTopGap]="0"
      [fixedBottomGap]="0"
    >
      <mat-nav-list>
        <a mat-list-item routerLink="/resources" routerLinkActive="active">
          <mat-icon class="mr-3">home</mat-icon>
          Resources
        </a>
        <a mat-list-item routerLink="/contact-information" routerLinkActive="active">
          <mat-icon class="mr-3">contact_page</mat-icon>
          Contact information
        </a>
        <a mat-list-item routerLink="/users" routerLinkActive="active">
          <mat-icon class="mr-3">people</mat-icon>
          Users
        </a>
        <a mat-list-item routerLink="/guides" routerLinkActive="active">
          <mat-icon class="mr-3">people</mat-icon>
          Guides
        </a>
        <a mat-list-item routerLink="/reports" routerLinkActive="active">
          <mat-icon class="mr-3">report</mat-icon>
          Reports
        </a>
        <a mat-list-item (click)="onLogout();">
          <mat-icon class="mr-3">power_settings_new</mat-icon>
          Logout
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet *ngIf="authService.isLoggedIn"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
