import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
 page;

  constructor(private _snackBar:MatSnackBar) { }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
   setPage( page  ) {
     this.page = page;
   }
   getPage (){
     return this.page;
   }
}
