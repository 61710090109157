import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guard/auth.guard';

const routes: Routes = [
  {
    redirectTo: '/resources',
    path: '',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'resources',
    loadChildren: () => import('./modules/resources/resources.module').then(m => m.ResourcesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'create-resource',
    loadChildren: () => import('./modules/resources/create-resource/create-resource.module').then(m => m.CreateResourceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contact-information',
    loadChildren: () => import('./modules/contact-information/contact-information.module').then(m => m.ContactInformationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'create-resource/:id',
    loadChildren: () => import('./modules/resources/create-resource/create-resource.module').then(m => m.CreateResourceModule),
    canActivate: [AuthGuard],
  },
  {
  path: 'users',
  loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
  canActivate: [AuthGuard],
}
,
  {
  path: 'guides',
  loadChildren: () => import('./modules/guides/guides.module').then(m => m.GuidesModule),
  canActivate: [AuthGuard],
},
{
  path: 'reports',
  loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
  canActivate: [AuthGuard],
},

{
  path: 'report-details/:reportType/:id',
  loadChildren: () => import('./modules/reports/report-details/report-details.module').then(m => m.ReportDetailsModule),
  canActivate: [AuthGuard],
},
{
  path: 'guide-details/:id',
  loadChildren: () => import('./modules/guides/guide-details/guide-details.module').then(m => m.GuideDetailsModule),
  canActivate: [AuthGuard],
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
