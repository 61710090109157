import { Injectable } from '@angular/core';
import { environment } from "./../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { catchError, retry, map, first } from "rxjs/operators";
import { SharedService } from "./shared.service";
import { Router } from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: any;
  authSubject = new BehaviorSubject(false);
  token$: BehaviorSubject<any>;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    public sharedService: SharedService,
  ) { }

  login(user): Observable<any> {
    return this.postApiResponse(
      `${environment.API_URL}` + "/user/authenticate",
      user
    );
  }
  get isLoggedIn(): boolean {
    const user = localStorage.getItem('token');
    return user !== null;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["login"]);
    this.sharedService.openSnackBar('logout successfully', '')
  }

  // Common
  postApiResponse(url: string, data: object): Observable<any> {
    return this.httpClient.post(url, data).pipe(
      map((response) => response),
      catchError((err) => {
        if (err.status === 401) {
          this.router.navigate(["login"]);
          return throwError(err);
        } else {
          return throwError(err);
        }
      })
    );
  }
}
